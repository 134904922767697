<template>
    <div :class="'endless-list-container ' + additionalClass">
        <ul class="endless-list">
            <li
                v-for="item in localItems.filter(i => i.deleted == 0)"
                class="endless-list-item"
                :key="item[keyProp]"
            >
                <div class="endless-list-item-slot">
                    <slot v-bind:item="item">{{ item }}</slot>
                </div>
                <b-button
                    v-if="!hideDeleteButton"
                    class="remove-button"
                    variant="danger"
                    v-b-tooltip.hover
                    title="Löschen"
                    :size="size"
                    :disabled="busy"
                    @click="removeItem(item[keyProp])"
                >
                    <font-awesome-icon icon="trash" />
                </b-button>
            </li>
        </ul>
        <b-button
            v-if="!hideNewButton"
            variant="primary"
            @click="addItem"
            :disabled="busy"
            :size="size"
            >{{ newButtonLabel }}</b-button
        >
    </div>
</template>

<script>
import shortid from "shortid";

import "./EndlessList.scss";

export default {
    name: "EndlessList",
    props: {
        value: {
            type: Array,
            default: () => []
        },
        keyProp: {
            type: String,
            default: "id"
        },
        additionalClass: {
            type: String,
            default: ""
        },
        busy: {
            type: Boolean,
            default: false
        },
        size: {
            type: String,
            default: "sm"
        },
        newItemBoilerplate: {
            type: Object,
            default: () => {}
        },
        newButtonLabel: {
            type: String,
            default: "Neuen Datensatz hinzufügen"
        },
        hideNewButton: {
            type: Boolean,
            default: false
        },
        hideDeleteButton: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            localItems: []
        };
    },
    watch: {
        value: {
            handler(value) {
                this.localItems = value;
            },
            immediate: true
        },
        localItems: {
            handler(value) {
                this.$emit("input", value);
            },
            deep: true
        }
    },
    methods: {
        addItem() {
            const newItem = { ...this.newItemBoilerplate };
            newItem.deleted = 0;
            newItem[this.keyProp] = "new_" + shortid.generate();
            this.localItems.push(newItem);
        },
        removeItem(id) {
            const item = this.localItems.find(item => item.id == id);
            item.deleted = 1;
        }
    }
};
</script>
