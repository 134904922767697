var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"persons-edit-view"},[_c('EndlessList',{attrs:{"newItemBoilerplate":{
            id: null,
            type: null,
            label_id: null,
            value: null
        },"hideNewButton":_vm.readonly,"hideDeleteButton":_vm.readonly},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var item = ref.item;
return [_c('b-input-group',{attrs:{"size":_vm.size}},[_c('b-input-group-prepend',[_c('b-select',{attrs:{"size":_vm.size,"options":_vm.contactTypes,"disabled":_vm.readonly},model:{value:(item.type),callback:function ($$v) {_vm.$set(item, "type", $$v)},expression:"item.type"}})],1),_c('b-input',{attrs:{"size":_vm.size,"placeholder":"Kontaktdaten einfügen...","disabled":_vm.readonly},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),_c('b-input-group-append',[_c('b-select',{attrs:{"size":_vm.size,"options":_vm.labelOptions,"disabled":_vm.readonly},model:{value:(item.label_id),callback:function ($$v) {_vm.$set(item, "label_id", $$v)},expression:"item.label_id"}})],1)],1)]}}]),model:{value:(_vm.data.contacts),callback:function ($$v) {_vm.$set(_vm.data, "contacts", $$v)},expression:"data.contacts"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }