<template>
    <div class="persons-edit-view">
        <EndlessList
            :newItemBoilerplate="{
                id: null,
                type: null,
                label_id: null,
                value: null
            }"
            :hideNewButton="readonly"
            :hideDeleteButton="readonly"
            v-model="data.contacts"
        >
            <template #default="{item}">
                <b-input-group :size="size">
                    <b-input-group-prepend>
                        <b-select
                            :size="size"
                            :options="contactTypes"
                            v-model="item.type"
                            :disabled="readonly"
                        />
                    </b-input-group-prepend>
                    <b-input
                        :size="size"
                        v-model="item.value"
                        placeholder="Kontaktdaten einfügen..."
                        :disabled="readonly"
                    ></b-input>
                    <b-input-group-append>
                        <b-select
                            :size="size"
                            :options="labelOptions"
                            v-model="item.label_id"
                            :disabled="readonly"
                        />
                    </b-input-group-append>
                </b-input-group>
            </template>
        </EndlessList>
    </div>
</template>

<script>
import Size from "@/mixins/Size/Size";
import EndlessList from "@/components/EndlessList/EndlessList";
import { mapActions, mapGetters } from "vuex";

export default {
    name: "PersonContactView",
    mixins: [Size],
    components: { EndlessList },
    props: {
        data: {
            type: Object
        },
        busy: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            systemLabels: [
                {
                    value: null,
                    text: "Label wählen...."
                },
                {
                    value: "pruefende",
                    text: "Prüfende"
                },
                {
                    value: "lehrende",
                    text: "Lehrende"
                },
                {
                    value: "teilnehmende",
                    text: "Teilnehmende"
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            storedContactTypes: "contactTypes/getData"
        }),
        contactTypes() {
            var data = [
                {
                    value: null,
                    text: "Kontakttyp wählen...."
                }
            ];
            data = data.concat(this.storedContactTypes);
            return data;
        },
        labelOptions() {
            const options = this.$store.getters["label/getSelectData"];
            options.unshift({ value: null, text: "Label auswählen..." });
            return options;
        }
    },
    watch: {
        busy: {
            handler(val) {}
        }
    },
    created() {
        this.fetchLabel();
    },
    methods: {
        ...mapActions({
            fetchLabel: "label/fetchData"
        })
    }
};
</script>
